<template>
  <AppNavbar />
  <VideoScreenWithShowcase />
  <ParkingScreen id="parking-screen" />
  <HomeScreen id="home-screen" />
  <PaymentScreen id="payment-screen"/>
  <VehicleScreen id="vehicle-screen"/>
  <AdministrationScreen id="administration-screen"/>

  <!--<AboutScreen id="about-screen"/>-->
  <ContactSheet/>
</template>

<script>
import AppNavbar from './components/Navbar.vue'
import VideoScreenWithShowcase from './components/AppShowcase.vue'
import ParkingScreen from './components/ParkingScreen.vue'
import PaymentScreen from './components/PaymentScreen.vue'
//import AboutScreen from './components/AboutScreen.vue'
import ContactSheet from './components/ContactScreen.vue'
import VehicleScreen from './components/VehicleScreen.vue'
import AdministrationScreen from './components/AdministrationScreen.vue'
import HomeScreen from './components/HomeScreen.vue'


export default {
name: 'App',
components: {
  AppNavbar,
  VideoScreenWithShowcase,
  ParkingScreen,
  PaymentScreen,
  AdministrationScreen,
  VehicleScreen,
  //AboutScreen,
  ContactSheet,
  HomeScreen
}
}
</script>

<style>
/* Reset margins and padding for the whole document */
html, body, #app {
margin: 0;
padding: 0;
height: 100%;
width: 100%;
}

/* html, body {
  background-color: rgba(77, 77, 77, 0.5);

} */

/* Ensure the app container fills the entire viewport */
#app {
font-family: Avenir, Helvetica, Arial, sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
text-align: center;
color: #2c3e50;
margin: 0;
padding: 0;
width: 100%;
height: 80%;
}

</style>
