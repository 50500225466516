<template>
  <div class="video-showcase-container">
    <!-- Video section -->
    <div class="video-container">
      <img 
      src="@/assets/frame.jpg" 
          /> 
      <div class="overlay"></div>

      <!-- Showcase section -->
      <div class="showcase">
        <h1 class="fade-in">
          Upoznajte novog parking asistenta!
        </h1>
        <div class="button-group">
          <a href="#" class="button primary">Preuzmi za iOS</a>
          <a href="#" class="button secondary">Preuzmi za Android</a>
        </div>
      </div>
    </div>

    <!-- Modern Scroll Indicator -->
    <div class="scroll-indicator" @click="scrollDown">
      <svg width="150" height="150" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 16L6 10H18L12 16Z" fill="#0091ff"/>
      </svg>
    </div>
  </div>
</template>




<script>
export default {
methods: {
    scrollDown() {
      window.scrollBy({
        top: window.innerHeight - 110, // Scroll down by the height of the viewport (100vh)
        behavior: 'smooth' // Smooth scrolling animation
      });
    }
  }
}
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400&display=swap');

/* Reset margins and paddings */
body, html {
  position: relative; 
  margin: 0;
  padding: 0; 
  height: 100%;
  overflow: hidden; /* Prevent scrolling */
}

.video-showcase-container {
  height: 100vh; /* Full viewport height */
  position: relative;
  background-color: #f8f8f8;
  box-sizing: border-box; 
}

/* Video container styling */
.video-container {
  position: relative; /* Absolute positioning */
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 80%; /* Full viewport height */
  overflow: hidden;
  background-color: #f8f8f8;
  box-sizing: border-box; 
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  /*clip-path: ellipse(80% 100% at 50% 0);*/
}

.video-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image is fully visible */
  object-position: center;
  transform: translate(-50%, -50%);
}

/* Overlay styling */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  /*clip-path: ellipse(80% 100% at 50% 0);*/
}

/* Showcase styling */
.showcase {
  position: absolute; /* Absolute positioning to overlay on the video */
  display: flex;
  flex-direction: column; /* Stack text vertically */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 50px;
  box-sizing: border-box;
  z-index: 10; /* Ensure it is above the video */
  color: white; /* Set text color to white */
  text-align: center; /* Center text inside showcase */
}

/* Heading styling */
.showcase h1 {
  font-size: 70px;
  color: white; /* White text color */
  margin-bottom: 20px;
  font-family: 'Dancing Script', cursive;
  opacity: 0; /* Start invisible */
  animation: fadeIn 5s forwards; /* Apply animation */
}

/* Paragraph styling */
.showcase p {
  font-size: 18px;
  color: white; /* White text color */
  margin-bottom: 30px;
}

/* Center button group horizontally */
.button-group {
  display: flex;
  justify-content: center; /* Center buttons horizontally */
  gap: 15px;
  margin-top: 30px; /* Space between text and buttons */
}

.button {
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.button.primary {
  background-color: #0091ff;
  color: white; /* Text color for primary button */
}

.button.primary:hover {
  background-color: #007acc;
}

.button.secondary {
  background-color: #fff; /* White background for secondary button */
  color: #333; /* Black text for secondary button */
}

.button.secondary:hover {
  background-color: #cacaca; /* Light gray background on hover */
  color: #000; /* Black text on hover */
}

/* Animation for whole text */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Class to apply fade-in animation */
.fade-in {
  animation: fadeIn 2s ease-in-out forwards;
}

/* Modern Scroll Indicator styling */
.scroll-indicator {
  position: absolute;
  bottom: -10px; /* 20px from the bottom */
  left: 50%;
  transform: translateX(-50%);
  animation: bounce 3s infinite; /* Bounce animation */
}

/* Bounce animation */
@keyframes bounce {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 80%, 100% {
    transform: translateX(-50%) translateY(0);
  }
  40% {
    transform: translateX(-50%) translateY(-20px);
  }
  60% {
    transform: translateX(-50%) translateY(-10px);
  }
}

@media (max-width: 768px) { 
  /* Class to apply fade-in animation */

  .button {
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
  font-size: 10px;
}
}


</style>
