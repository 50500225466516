<template>
  <div class="navbar">
    <div
      class="navbar-logo"
      @mouseover="showText = true"
      @mouseleave="showText = scrollY === 0"
      @click="scrollToTop"
    >
      <img
        src="@/assets/logo/ic_parkvision_logo_white.png"
        alt="Parking Logo"
        v-if="showText || scrollY !== 0"
        class="default-logo"
      />
      <img
        src="@/assets/logo/ic_parkvision_logo.png"
        alt="Parking Logo"
        v-if="showText || scrollY === 0"
        class="default-logo"
      />
      <img
        src="@/assets/logo/ic_parkvision_logo.png"
        alt="Parking Logo"
        class="hover-logo"
      />
      <span
        v-if="showText || scrollY === 0"
        class="logo-text"
      >
        <span class="park-text">Park</span>
        <span class="vision-text"> Vision</span>
      </span>
    </div>
    <div class="navbar-buttons">
    <!--  <a
        @click="scrollToSection('parking-screen')"
        class="button"
        :class="{ active: activeButton === 'parking-screen' }"
        @mouseover="isHovered = '/list'"
        @mouseleave="isHovered = null">
        <i class="fas fa-list"></i>
        <span class="tooltip" v-if="isHovered === '/list'">Zašto odabrati nas?</span>
      </a>-->

      <!-- Button for Navigation/Map -->
      <a
        @click="scrollToSection('home-screen')"
        class="button"
        :class="{ active: activeButton === 'home-screen' }"
        @mouseover="isHovered = '/home'"
        @mouseleave="isHovered = null">
        <i class="fas fa-map-marked-alt"></i>
        <span class="tooltip" v-if="isHovered === '/home'">Navigacija</span>
      </a>


    <!-- Button for Billing -->
      <a
        @click="scrollToSection('payment-screen')"
        class="button"
        :class="{ active: activeButton === 'payment-screen' }"
        @mouseover="isHovered = '/payment'"
        @mouseleave="isHovered = null">
        <i class="fas fa-credit-card"></i>
        <span class="tooltip" v-if="isHovered === '/payment'">Plaćanje parkinga</span>
      </a>

    <!-- Button for Vehicles -->

    <a
        @click="scrollToSection('vehicle-screen')"
        class="button"
        :class="{ active: activeButton === 'vehicle-screen' }"
        @mouseover="isHovered = '/vehicle'"
        @mouseleave="isHovered = null">
        <i class="fas fa-car"></i>
        <span class="tooltip" v-if="isHovered === '/vehicle'">Vaša vozila</span>
      </a>

    <!-- Button for Administrators -->

    <a
        @click="scrollToSection('administration-screen')"
        class="button"
        :class="{ active: activeButton === 'administration-screen' }"
        @mouseover="isHovered = '/administration'"
        @mouseleave="isHovered = null">
        <i class="fas fa-user-shield"></i>
        <span class="tooltip" v-if="isHovered === '/administration'">Administratorski panel</span>
      </a>

      <!--<a
        @click="scrollToSection('about-screen')"
        class="button"
        :class="{ active: activeButton === 'about-screen' }"
        @mouseover="isHovered = '/about'"
        @mouseleave="isHovered = null">
        <i class="fas fa-info-circle"></i>
        <span class="tooltip" v-if="isHovered === '/about'">Ko smo mi?</span>
      </a>-->
     
      <a
        @click="scrollToBottom"
        class="button contact"
        :class="{ active: activeButton === 'contact' }"
      >
      KONTAKT
        <!--<i class="fas fa-envelope"></i>-->
        <!--<span class="tooltip" v-if="isHovered === '/contact'">Kontakt</span>-->
      </a>

      <!-- Login button 
      <a href="/login" class="button login" :class="{ active: isActive('/login') }">
        LOGIN
      </a>-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppNavbar',
  data() {
    return {
      isHovered: null,
      showText: false,
      scrollY: window.scrollY,
      activeButton: null,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);

    const options = {
      root: null,
      threshold: 0.5,  // Adjust threshold if needed
    };

    this.observer = new IntersectionObserver(this.handleIntersection, options);

    const sections = ['home-screen', 'payment-screen', 'vehicle-screen', 'administration-screen', 'contact'];
    sections.forEach(id => {
      const section = document.getElementById(id);
      if (section) {
        this.observer.observe(section);
      }
    });
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    handleScroll() {
      this.scrollY = window.scrollY;
      const scrollPosition = window.scrollY + window.innerHeight;
      const pageHeight = document.documentElement.scrollHeight;

      if (scrollPosition >= pageHeight) {
        this.activeButton = 'contact';
      } else {
        if (this.activeButton === 'contact') {
          this.activeButton = null;
        }
        this.showText = this.scrollY > 100 ? false : true;
      }
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.showText = true;
    },
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        const sectionPosition = section.getBoundingClientRect().top + window.scrollY;
        let offsetPosition; 

        if (sectionId === "home-screen") {
      offsetPosition = sectionPosition - 70;
    } else if (sectionId === "payment-screen") {
      offsetPosition = sectionPosition - 30;
    } else if (sectionId === "vehicle-screen") {
        offsetPosition = sectionPosition - 95;
    } else {
      offsetPosition = sectionPosition;
    }

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
      this.activeButton = sectionId;
    },
    scrollToBottom() {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
      this.activeButton = 'contact';
    },
    handleIntersection(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.activeButton = entry.target.id;
        } else if (this.activeButton === entry.target.id) {
          this.activeButton = null;
        }
      });
    },
    isActive(path) {
      return this.activeButton === path;
    },
  },
};
</script>

<style scoped>
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 20px);
  background-color: rgb(77, 77, 77);
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  margin: 10px;
  margin-right: 20px;
  border-radius: 20px;  
  z-index: 1000;
  box-sizing: border-box;
}

.navbar-logo {
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.navbar-logo img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: auto;
}

.default-logo {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.hover-logo {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.navbar-logo:hover .default-logo {
  opacity: 0;
}

.navbar-logo:hover .hover-logo {
  opacity: 1;
}

.logo-text {
  display: inline-block;
  margin-left: 50px;
  font-size: 24px;
  transition: opacity 0.3s ease;
}

.park-text {
  color: #0091ff;
  font-weight: bold;
}

.vision-text {
  color: white;
}

.navbar-buttons {
  display: flex;
  gap: 15px;
}

.button {
  background-color: rgba(77, 77, 77);
  color: white;
  padding: 0px 5px;
  border-radius: 15px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  position: relative;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.button:hover {
  background-color: rgba(0, 145, 255);
}

.button.active {
  background-color: rgba(0, 145, 255);
  color: white;
}

.tooltip {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1001;
}

.button.contact {
  background-color: white;
  color: rgb(77, 77, 77);
  font-weight: bold;
  border-radius: 15px;
  padding: 0px 30px;
  text-decoration: none;
  font-size: 14px; 
}

.button.contact.active {
  background-color: rgba(0, 145, 255);
  color: white;
}

.button.contact:hover {
  background-color: rgba(0, 145, 255);
  color: white;
}

@media (max-width: 768px) {
  .navbar {
    width: calc(100% - 20px);
    margin: 10px;
    margin-right: 20px;
    border-radius: 20px;  
    padding: 5px 10px;
    border-radius: 10px;
  }

  .navbar-logo {
    height: 20px;
  }

  .navbar-logo img {
    height: 20px;
  }

  .logo-text {
    font-size: 12px;
    margin-left: 25px;
  }

  .navbar-buttons {
    gap: 7px;
  }

  .button {
    width: 20px;
    height: 20px;
    padding: 0px 2px;
  }

  .button .fas {
    font-size: 12px;
  }

  .button.contact {
    font-size: 8px;
    padding: 0px 15px;
  }

  .tooltip {
    font-size: 10px;
    padding: 3px 5px;
  }
}

</style>
