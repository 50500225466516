<template>
  <footer class="footer">
    <div class="contact-section">
      <h2>Kontaktirajte nas</h2>
      <div class="contact-info">
          <div class="info-item">
  <i class="fas fa-envelope"></i>
  <a href="mailto:info@park-vision.com" style="text-decoration: none; color: inherit;">
    <span>info@park-vision.com</span>
  </a>
</div>

        <!--<div class="info-item">
          <i class="fas fa-phone"></i>
          <span>-</span>
        </div>
        <div class="info-item">
          <i class="fas fa-map-marker-alt"></i>
          <span>-</span>
        </div>-->
      </div> 
    </div>
    <div class="footer-bottom">
      <p>&copy; 2024 Park Vision. Sva prava zadržana.</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'ContactSheet',
};
</script>

<style scoped>
 .footer {
  background-color: #f8f8f8;
  padding: 40px 20px;
  text-align: center;
  border-top: 1px solid #ddd;
  position: relative; /* Ili 'fixed' ako želite da bude uvijek na dnu */
  bottom: 0;
  width: 100%;
  font-family: 'Arial', sans-serif;
  box-sizing: border-box; /* Dodano da se osigura da padding i border ne povećavaju širinu */
}


.contact-section {
  margin-bottom: 20px;
}

.contact-section h2 {
  color: #333;
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 24px;
}

.contact-info {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.info-item {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  color: #555;
}

.info-item i {
  font-size: 24px;
  color: #0091ff;
}

.footer-bottom {
  margin-top: 30px;
  color: #777;
  font-size: 14px;
}
</style>
