<template>
  <!-- Showcase section -->
  <div class="showcase">
    <div class="content">
      <div class="video-section">
        <video
          ref="showcaseVideo"
          class="video-showcase"
          autoplay
          muted
          loop
          playsinline
          :src="videoSrc"/>
      </div>

      <div class="text-section">
        <h1>{{ currentTitle }}</h1>
        <p>{{ currentDescription }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentTitle: "PARKING I NAVIGACIJA",
      currentDescription: "Na početnom zaslonu aplikacije prikazana je lista svih dostupnih parkinga u krugu od 5 km od vaše trenutne lokacije. Svaki parking možete detaljno pregledati, sa svim relevantnim informacijama. Pored liste, na raspolaganju vam je i interaktivna mapa koja prikazuje sve parkinge u vašoj blizini. Također, možete koristiti ugrađenu navigaciju na vašem iOS ili Android uređaju kako biste lako stigli do odabranog parking mjesta.",
      videoSrc: require('@/assets/app-ios.mp4') // Path to your video file
    };
  },
  mounted() {
    // Set video playback speed to 0.9 when component is mounted
    // Ensure the video is loaded before setting currentTime
    const video = this.$refs.showcaseVideo;

  video.onloadedmetadata = () => {
    video.currentTime = 67; // Start video at 10 seconds (change this to your desired time)
    video.playbackRate = 1.0; // Set playback speed to 0.9
  };
  },
  methods: {
    scrollDown() {
      window.scrollBy({
        top: window.innerHeight, // Scroll down by the height of the viewport (100vh)
        behavior: 'smooth' // Smooth scrolling animation
      });
    }
  }
}
</script>

<style scoped>
/* Reset margins and paddings */
body, html {
  margin: 0;
  padding: 0;
  height: 80vh;
  overflow: hidden; /* Prevent scrolling */
}

/* Showcase styling */
.showcase {
  position: relative;
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  box-sizing: border-box;
  z-index: 10; /* Ensure it is above other content */
  overflow: hidden; /* Hide overflowing content during transitions */
  background-color: #f8f8f8;
}

/* Content styling */
.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80%;
}

/* Video section styling */
.video-section {
  position: relative;
  max-width: 40%;
  margin-left: 50px;
  background: none; /* Ensure the container has no background */
  display: flex; /* Ensure the container resizes based on content */
  justify-content: center;
  align-items: center;
  padding-top: -20px;
  padding-left: 50px;
}

.video-showcase {
  width: 90%;
  height: 90%;
  border-radius: 10px;
  object-fit: cover; /* Ensure the video covers the container without stretching */
  background: none; /* Ensure the video has no background */
  border-radius: 40px;
}


/* Text section styling */
.text-section {
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  text-align: justify;
}

.text-section h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.text-section p {
  font-size: 18px;
  margin-bottom: 30px;
}

/* Modern Scroll Indicator styling */
.scroll-indicator {
  position: absolute;
  bottom: -10px; /* 20px from the bottom */
  left: 50%;
  transform: translateX(-50%);
  animation: bounce 3s infinite; /* Bounce animation */
}

/* Bounce animation */
@keyframes bounce {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 80%, 100% {
    transform: translateX(-50%) translateY(0);
  }
  40% {
    transform: translateX(-50%) translateY(-20px);
  }
  60% {
    transform: translateX(-50%) translateY(-10px);
  }
}


/* Mobile view adjustments */
@media only screen and (max-width: 768px) {

  /* Showcase styling */
.showcase {
  position: relative;
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  box-sizing: border-box;
  z-index: 10; /* Ensure it is above other content */
  overflow: hidden; /* Hide overflowing content during transitions */
  background-color: #f8f8f8;
}

  .content {

    flex-direction: column; /* Stack content vertically */
    align-items: center;
  }

  .text-section {
    max-width: 100%; /* Full width for mobile */
    text-align: center; /* Center text */
    padding: 0px;
  }

  .text-section h1 {
  font-size: 20px;
  margin-bottom: 5px;
}

.text-section p {
  font-size: 13px;
  margin-bottom: 10px;
  text-align: justify;
  padding-left: 20px;
  padding-right: 20px;
}


  .video-section {
    max-width: 45%; /* Full width for mobile */
    margin-left: 0;
    padding-left: 0;
    padding-top: 0px; /* Add padding to separate video from text */
  }

  .video-showcase {
    width: 90%; /* Make the video fill the width on mobile */
    height: auto; /* Maintain aspect ratio */
    border-radius: 23px;

  }
}
</style>
