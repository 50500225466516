<template>
  <div>
    <h2>ADMINISTRATORSKI PREGLED</h2>
    <div class="showcase">
      <div class="content">
        <div 
          class="box" 
          v-for="(box, index) in boxes" 
          :key="index"
          @mouseover="scaleBox" 
          @mouseleave="resetScaleBox"
        >
          <img 
            :src="getImagePath(box.image)" 
            :alt="'Phone Image ' + index" 
            class="image"
          />
          <div class="text-box">
            <p>{{ box.text }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      boxes: [
        {
          image: 'admin_screen_1.png',
          text: 'Administratori imaju pregled nad svim parking mjestima s ključnim podacima poput broja slobodnih mjesta, trenutne popunjenosti i ukupnog kapaciteta. Ovaj ekran omogućava efikasno upravljanje parking kapacitetom i brzu identifikaciju dostupnih lokacija.'
        },
        {
          image: 'admin_screen_2.png',
          text: 'Modul za pretragu registarskih oznaka omogućava administratorima jednostavno provjeravanje statusa parking karti. Na ovaj način, vlasnici parkinga mogu brzo i efikasno upravljati korisničkim parking pravima i vršiti inspekcije.'
        },
        {
          image: 'admin_screen_3.png',
          text: 'Detaljan pregled svih kazni prema korisnicima, uključujući informacije o prekršajima, datumima i statusima kazni. Administratori mogu lako pratiti učestalost i vrstu prekršaja, omogućavajući bolje provođenje pravila parkiranja.'
        },
        {
          image: 'admin_screen_4.png',
          text: 'Administratori koriste ovaj ekran za unos i upravljanje kaznama. Omogućeno je dodavanje novih prekršaja, kao i ažuriranje postojećih kazni, čime se osigurava sveobuhvatan nadzor nad kaznama i prekršajima unutar parking sistema.'
        }
      ]
    };
  },
  methods: {
    getImagePath(image) {
      return require(`@/assets/screen/${image}`);
    },
    scaleBox(event) {
      event.currentTarget.style.transform = "scale(1.1)";
    },
    resetScaleBox(event) {
      event.currentTarget.style.transform = "scale(1)";
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

body, html {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(135deg, #0091ff, #00c1ff);
  font-family: 'Poppins', sans-serif;
}

h2 {
  text-align: start;
  margin-left: 50px;
  margin-top: 50px;
  margin-bottom: -40px;
}

.showcase {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.content {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.box {
  width: 30%;
  background-color: #f0f0f0; /* Svijetlosiva pozadina */
  padding: 20px;
  border-radius: 15px;
  margin: 10px;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.3);
  text-align: center;
  color: black; /* Crni tekst za bolji kontrast */
  transition: transform 0.3s ease-in-out;
}

.box:hover {
  transform: scale(1.1); /* Scale up on hover */
}

.image {
  width: 45%;
  border-radius: 10px;
  margin-bottom: 10px;
}

.text-box {
  padding-left: 30px;
  padding-right: 30px;
  font-size: 14px;
  line-height: 1.0;
  text-align: justify;
  max-height: none; /* Ensure the height is not restricted */
  white-space: normal; /* Ensure text wraps */
  overflow: visible; /* Show the entire text */
}

/* Mobile view adjustments */
@media (max-width: 768px) {
  .content {
    display: flex;
    flex-direction: row;
    overflow-x: scroll; /* Horizontal scroll */
    white-space: nowrap;
  }

  h2 {
  text-align: start;
  font-size: 20px;
  margin-left: 50px;
  margin-top: 50px;
  margin-bottom: -40px;
}

  .box {
    width: 80%; /* Wider box for mobile */
    margin-right: 15px; /* Add space between boxes */
    flex-shrink: 0; /* Prevent shrinking */
  }

  .image {
    width: 60%; /* Make images larger on mobile */
  }
}

</style>
