<template>
  <div class="intro-text">
    <h2>Više od običnog parkinga</h2>
    <p>
      Pored jednostavnog pronalaženja parking mjesta, aplikacija omogućava navigaciju, 
      plaćanje, evidenciju prethodno korištenih parkirališta i mnoge druge korisne opcije za jednostavnije parkiranje.
    </p>
  </div>
  
  <div class="showcase">
    <div class="content">
      <div 
        class="box" 
        v-for="(box, index) in boxes" 
        :key="index"
        @mouseover="scaleBox" 
        @mouseleave="resetScaleBox"
      >
        <img 
          :src="box.icon.src" 
          :alt="'Icon for ' + box.title" 
          :width="box.icon.width" 
          :height="box.icon.height"
          class="image"
        />
        <div class="text-box">
          <h3>{{ box.title }}</h3>
          <p>{{ box.description }}</p>
        </div>
      </div>
    </div>
  </div>

  <!-- Sekcija za brojače -->
  <div class="stats-container">
    <div class="stat-item">
      <h2>{{ parkingCount }}+</h2>
      <p>PARKING MJESTA</p>
    </div>
    <div class="stat-item">
      <h2>{{ userCount }}+</h2>
      <p>KORISNIKA</p>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      // Box items (sadržaj boxova)
      boxes: [
        {
          title: 'PARKING',
          description: 'Brzo pronađite parking mjesta u vašoj blizini. \nJednostavno odaberite željeno parking mjesto i pregledajte informacije.',
          icon: {
            src: require('../assets/icon/ic_parking.png'),
            width: '40',
            height: '40'
          }
        },
        {
          title: 'NAVIGACIJA',
          description: 'Aplikacija omogućava korisniku da odabere željeno parking mjesto i pokrene navigaciju, koja ga vodi direktno do odabranog parkinga.',
          icon: {
            src: require('../assets/icon/ic_navigation.png'),
            width: '40',
            height: '40'
          }
        },
        {
          title: 'PLAĆANJE',
          description: 'Možete jednostavno vršiti plaćanje parkinga sa vašeg mobilnog uređaja. Pored toga, omogućen je pregled statusa vaših parkirnih karti i obavijest o isteku karte.',
          icon: {
            src: require('../assets/icon/ic_billing.png'),
            width: '40',
            height: '40'
          }
        },
        {
          title: 'VOZILA',
          description: 'Korisnici mogu kreirati sopstveno vozilo što im prilikom plaćanja eliminiše potreba za ponovnim unosom registarskih oznaka, omogućujući brže i jednostavnije plaćanje.',
          icon: {
            src: require('../assets/icon/ic_car.png'),
            width: '40',
            height: '40'
          }
        },
        {
          title: 'ADMINISTRACIJA',
          description: 'Vlasnici imaju mogućnost pregleda svih aktivnih parking karata. Omogućeno je i evidentiranje kazni za vozila koja nisu propisno parkirana, čime se olakšava upravljanje parkinzima.',
          icon: {
            src: require('../assets/icon/ic_admin.png'),
            width: '40',
            height: '40'
          }
        }
      ],

      // Brojači
      parkingCount: 50,
      userCount: 20,

      // Ciljne vrijednosti brojača
      parkingTarget: 50,
      userTarget: 20,
    };
  },
  mounted() {
  // Simulirajte dohvaćanje podataka (možete zamijeniti stvarnim API pozivom)
  setTimeout(() => {
    this.parkingCount = 50; // Ovdje dobijete stvarnu vrijednost iz API-a
    this.userCount = 20; // Ovdje dobijete stvarnu vrijednost iz API-a
    this.incrementCounter('parkingCount', this.parkingCount, 50); 
    this.incrementCounter('userCount', this.userCount, 20);
  }, 2000);
},
  methods: {
    scaleBox(event) {
      event.currentTarget.style.transform = "scale(1.1)";
    },
    resetScaleBox(event) {
      event.currentTarget.style.transform = "scale(1)";
    },
    incrementCounter(counter, target, speed) {
  const increment = () => {
    if (this[counter] < target) {
      this[counter] = Math.min(this[counter] + Math.ceil(target / (1000 / speed)), target); // Povećava se do cilja
      setTimeout(increment, speed);
    } else {
      this[counter] = target; // Postavi na ciljnu vrijednost
    }
  };
  increment();
}

  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

body, html {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(135deg, #0091ff, #00c1ff);
  font-family: 'Poppins', sans-serif;
}

.showcase {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.content {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.box {
  width: 30%;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 15px;
  margin: 20px;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.3);
  text-align: center;
  color: black;
  transition: transform 0.3s ease-in-out;
}

.box:hover {
  transform: scale(1.1);
}

.image {
  border-radius: 10px;
  margin-bottom: 10px;
}

.text-box {
  padding-left: 25px;
  padding-right: 25px;
  font-size: 14px;
  line-height: 1.4;
  text-align: justify;
  white-space: pre-wrap; /* Keep newline characters */
}

.text-box h3 {
  text-align: center;
}

.stats-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
}

.stat-item h2 {
  font-size: 50px;
  color: #0091ff;
  margin: 0;
}

.stat-item p {
  font-size: 20px;
  color: #333;
  margin: 10px 0 0;
}

@media (max-width: 768px) {
  .content {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    white-space: nowrap;
  }

  .box {
    width: 80%;
    margin-right: 15px;
    flex-shrink: 0;
  }
  
  .intro-text {
    text-align: center;
    color: black;
    margin-bottom: -80px;
    margin-left: 20px;
    margin-right: 20px;

  }

  .intro-text h2 {
    font-size: 20px;
  }

  .intro-text p {
    text-align: justify;
    font-size: 13px;  }

  .stats-container {
    margin-top: -80px;
    flex-direction: column;
  }

  .stat-item h2 {
    font-size: 36px;
  }

  .stat-item p {
    font-size: 16px;
  }
}
</style>
