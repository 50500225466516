<template>
  <div class="showcase">
    <div class="content">
      <div class="text-section">
        <h2>VAŠA VOZILA</h2>
        <p :class="{'text-module': true, 'selected': selectedText === 1 || hoveredText === 1}">
          Prva slika prikazuje intuitivan ekran aplikacije s listom vozila koje je korisnik prethodno kreirao na svom profilu. Ovaj pregled omogućava korisnicima jednostavno upravljanje vozilima – uz samo nekoliko koraka, mogu dodati novo vozilo ili ukloniti postojeće.
        </p>
        <p :class="{'text-module': true, 'selected': selectedText === 2 || hoveredText === 2}">
          Kreiranje novog vozila vrši se kroz unos svih relevantnih informacija kao što su registarske oznake, naziv vozila i tipa vozila. Ovaj jednostavan interfejs vodi korisnika kroz proces dodavanja novog vozila, osiguravajući da svaki unos bude precizan i kompletan.
        </p>
      </div>

      <div class="image-section">
        <img 
          src="@/assets/screen/vehicle_screen_1.png" 
          alt="Phone Image" 
          class="image-left" 
          :class="{ 'zoomed-left': selectedText === 1, 'image-left-inactive': selectedText !== 1 }"
          @mouseover="hoverText(1)" 
          @mouseleave="resetText"
          @click="selectText(1)" 
        />
        <img 
          src="@/assets/screen/vehicle_screen_2.png" 
          alt="Phone Image" 
          class="image-right" 
          :class="{ 'zoomed-right': selectedText === 2, 'image-right-inactive': selectedText !== 2  }"
          @mouseover="hoverText(2)" 
          @mouseleave="resetText"
          @click="selectText(2)" 
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hoveredText: null,
      selectedText: null,
    };
  },
  methods: {
    hoverText(index) {
      this.hoveredText = index;
    },
    resetText() {
      this.hoveredText = null;
    },
    selectText(index) {
      this.selectedText = index;
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

body, html {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(135deg, #0091ff, #00c1ff);
  font-family: 'Poppins', sans-serif;
}

.showcase {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  background-color: #f8f8f8;
}

.content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 50px;
  box-sizing: border-box;
}

.text-section {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: justify;
  margin-left: 20px;
}

.text-section p {
  color: black;
}

.text-module {
  font-size: 18px;
  color: #666;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0.7;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.5); /* Dodavanje sjenke */
}

.text-module.selected, 
.text-module:hover {
  transform: translateX(50px);
  opacity: 1;
}

.image-section {
  position: relative;
  max-width: 40%;
  display: flex;
}

.image-left, .image-right {
  width: 45%;
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s ease, z-index 0.3s;
}

.zoomed-left {
  z-index: 5;
  margin-right: -15px;
  transform: rotate(0deg) scale(1.05);
}

.zoomed-right {
  z-index: 5;
  margin-left: -15px;
  transform: rotate(0deg) scale(1.05);
}

.image-left-inactive {
  transform: rotate(-5deg) scale(0.8);
  margin-right: -15px;
  z-index: 2;
}

.image-right-inactive {
  transform: rotate(5deg) scale(0.8);
  margin-left: -15px;
  z-index: 1;
}

.image-left:hover, .image-right:hover {
  z-index: 4;
  transform: rotate(0deg) scale(1.0);
}

/* Mobile Styles */
@media (max-width: 768px) {
  .showcase {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  background-color: #f8f8f8;
}

h2 {
  font-size: 20px;

}

.content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 50px;
  box-sizing: border-box;
}
  .text-section {
    width: 70%;
    margin-left: 0;
    text-align: center;
  }

  .image-section {
    flex-direction: column; /* Stack images vertically */
    width: 50%;
    max-width: 60%;
    margin-top: 65px;
    align-items: end;
    justify-content: center;
  }

  .image-left, .image-right {
    width: 70%; /* Make images full-width */
    margin: 20px 0; /* Add spacing between images */
    transform: none; /* Reset transformations */
  }

  .text-module {
    font-size: 10px;
    margin-top: 20px;
    text-align: justify;
    transform: none; /* Reset transformations */
    opacity: 1; /* Ensure full opacity on mobile */
  }

  .text-module:hover, .text-module.selected {
    transform: none;
  }


}
</style>
